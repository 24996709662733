@import '../../variables.scss';

.rounds-view {
    text-align: left;
    padding: 10px;

    .reload-btn {
        float: right;
        line-height: 2.5em;
        font-size: 1.5em;
        margin-right: 1em;
    }
}
