@import '../../variables.scss';

.courses-view {
    text-align: left;
    padding: 10px;

    .courses {
        color: $font-color;
        text-align: center;

        .course {
            background-color: $option-background-color;
            margin: 15px auto;
            line-height: 1em;
            padding: 1em;

            h1 {
                font-size: 1.5em;
                margin-top: 0.1em;
                margin-bottom: 0.5em;
            }
            label {
                margin-left: 0.2em;
                margin-right: 0.2em;
            }
            span {
                margin-left: 0.2em;
                margin-right: 0.2em;
                color: $accent-color;
            }
        }
    }
}
