th {
    vertical-align: top;
}
.total {
    padding: 0.2em;
    text-align: left;
    font-size: 0.5em;
    font-weight: 300;
    div:first-of-type {
        font-size: 1.1em;
        font-weight: 700;
    }
}
.player-score {
    padding: 0.2em;
    text-align: center;
    font-size: 0.5em;
    font-weight: 300;

    div {
        display: inline;
        font-size: 1em;
        &:first-of-type {
            font-weight: 700;
            margin-right: 3px;
        }
        &:last-of-type {
            display: block;
            font-size: 0.9em;
        }
    }
}
