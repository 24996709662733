.score {
    position: relative;
    font-weight: 500;
    width: 55px;
    min-width: 55px;
    border-radius: 3px;
    box-sizing: border-box;

    &.albatross,
    &.eagle,
    &.birdie {
        background-color: rgb(216, 246, 255);
    }
    &.par {
        background-color: rgb(217, 255, 216);
    }
    &.bogey {
        background-color: rgb(255, 252, 217);
    }
    &.doublebogey {
        background-color: rgb(254, 233, 216);
    }
    &.triplebogey {
        background-color: rgb(255, 215, 216);
    }
    &.unplayed {
        background-color: rgba(221, 221, 221, 0.5);
    }
    .score-container {
        select {
            opacity: 0;
            position: absolute;
            top: 0;
            left: 0;
            width: 50px;
            height: 40px;
        }
    }
}
