@import '../../variables.scss';

.user-view {
    text-align: center;
    padding: 10px;
    height: calc(100% - #{$menu-height});
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .username-form {
        width: 100%;
        height: 150px;
        input {
            font-family: 'Quicksand';
            font-size: 1.2em;
            outline: none;
            padding: 15px 5%;
            border-radius: 15px;
            border: none;
            margin-right: 5px;
            background: rgba(255, 255, 255, 1);
        }

        button {
            font-family: 'Quicksand';
            font-size: 1.2em;
            background: $button-color;
            color: #fff;
            border-radius: 15px;
            border: 0;
            padding: 15px 5%;
            margin-left: 5px;
        }
    }
}
