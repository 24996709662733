@import '../../../variables.scss';

.round {
    background-color: $option-background-color;
    padding: 8px 10px 0 10px;
    margin: 15px auto;

    .course {
        font-size: 1.2em;
        font-weight: bold;
    }

    .date {
        font-size: 0.9em;
        margin-bottom: 5px;
        div {
            display: inline;
        }
        .day {
            color: $accent-color;
            margin-right: 5px;
        }
        .month {
            margin-right: 5px;
        }
    }

    .players {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-wrap: wrap;

        .player {
            margin: 0 15px 10px 0;
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            font-size: 0.7em;
            .player-image {
                width: 30px;
                height: 30px;
                border-radius: 15px;
                margin-right: 5px;
            }
            .player-name {
                font-weight: bold;
            }
            .player-score {
                color: $font-color-secondary;
                white-space: nowrap;
                font-size: 1.1em;
            }
        }
    }

    .bio {
        float: right;
        i {
            color: $gold;
        }
    }
}
