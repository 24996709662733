@import '../../../variables.scss';

.date-form {
    z-index: 99;
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.8);
    padding: 1em;
    box-sizing: border-box;

    input {
        outline: none;
        padding: 20px 7%;
        border-radius: 20px;
        border: none;
        margin-bottom: 5%;
        background: rgba(255, 255, 255, 1);
        font-family: 'Quicksand';
        font-size: 0.6em;
    }

    .buttons {
        text-align: left;
        padding: 0.5em 0;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;

        button {
            font-family: 'Quicksand';
            font-size: 0.7em;
            background: $button-color;
            color: #fff;
            border-radius: 10px;
            border: 0;
            padding: 0.7em;
        }
    }
}
