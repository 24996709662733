@import '../../../variables.scss';

.add-player-btn {
    border: 3px solid $button-color;
    border-radius: 35px;
    width: 35px;
    height: 35px;
    line-height: 1.1em;
    float: right;
    margin-top: 10px;
    margin-right: 10px;
    .add-player-btn-icon {
        font-size: 1.2em;
        font-weight: 500;
        color: $button-color;
    }
}
