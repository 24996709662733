$background-color: rgba(20, 20, 20, 1);
$accent-color: rgba(244, 134, 147, 1);
$font-color: rgba(250, 250, 250, 1);
$font-color-secondary: rgba(250, 250, 250, 0.8);

$menu-background-color: rgba(30, 30, 30, 1);
$menu-font-color: $font-color;
$menu-border-color: rgba(50, 50, 50, 1);
$menu-height: 100px;

$header-border-color: rgba(50, 50, 50, 1);

$option-background-color: rgba(35, 35, 35, 1);

$button-color: cadetblue;
$gold: rgb(255, 217, 0);
