@import '../../variables.scss';

.menu {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: $menu-background-color;
    border-top: 1px solid $menu-border-color;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 20px;
    box-sizing: border-box;
    height: $menu-height;

    .menu-item {
        color: $menu-font-color;
        text-decoration: none;
        padding: 20px 10px 40px 10px;
        width: 33%;
        &.selected {
            color: $button-color;
        }
    }
}
