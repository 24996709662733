.camera-buttons {
    margin-top: 20px;

    .capture-button {
        position: relative;
        width: 100px;
        height: 100px;
        display: inline-block;

        .circle {
            position: absolute;
            top: 12%;
            left: 12%;
            bottom: 12%;
            right: 12%;
            border-radius: 100%;
            background-color: #ffffff;
            opacity: 1;
        }

        .ring {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;

            border-radius: 100%;
            border: 8px solid #ffffff;
            opacity: 1;
        }
    }

    .cancel-button {
        font-size: 15px;
        margin-top: 20px;
    }

    .switch-camera-button {
        position: relative;
        top: -115px;
        left: -25px;
        float: right;
        font-size: 28px;
    }
}
