@import '../../variables.scss';

.offline-indicator {
    width: 100%;
    text-align: center;
    position: absolute;
    color: $accent-color;
    line-height: 20px;
    z-index: 100;
    svg {
        vertical-align: bottom;
    }
}
