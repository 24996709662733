@import '../../variables.scss';

.image-upload {
    position: relative;
    height: 480px;

    .image {
        border-radius: 50%;
        width: 300px;
        height: 300px;
        margin: 0 auto 20px auto;
        img {
            background-color: $background-color;
            border-radius: 50%;
            width: 300px;
            height: 300px;
            margin: 0 auto;
        }
    }

    input {
        font-family: 'Quicksand';
        font-size: 1.2em;
        color: #fff;
        border-radius: 15px;
        border: 1px solid #{$button-color};
        width: 250px;
        margin-top: 20px;
    }

    .camera-button {
        display: block;
        font-size: 30px;
        line-height: 60px;
        width: 60px;
        margin: auto;
        border-radius: 15px;
        background-color: $button-color;
    }

    .camera {
        position: absolute;
        top: 0;
        width: 100%;

        .cropper {
            position: relative;
            overflow: hidden;
            width: 300px;
            height: 300px;
            border-radius: 50%;
            margin: 0 auto;

            video {
                display: inline;
                margin: 0 auto;
                height: auto;
                width: 100%;
            }
        }
    }
}
