@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;500;700&display=swap');
@import './variables.scss';

html,
body {
    font-size: 0.95em;
    color: $font-color;
    height: 100%;
    margin: 0;
}

.main-container {
    height: 100vh;
    width: 100vw;
    font-family: 'Quicksand';
    text-align: center;
    .view-container {
        height: calc(100vh - 70px);
        overflow-y: auto;
    }
}
