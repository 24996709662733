@import '../../variables.scss';

.scorecard {
    background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.418)),
        url('../../images/bg.jpg');
    background-size: cover;
    background-position: left;
    height: 100%;

    color: #000;
    margin: 0;
    font-family: 'Quicksand';
    font-size: 2em;
    text-align: center;
    overflow-x: auto;
    padding-top: 70px;

    .header {
        position: fixed;
        z-index: 99;
        top: 0;
        width: 100%;
        height: 60px;
        padding-top: 10px;
        border-bottom: 1px solid $header-border-color;
        background-color: $background-color;
        color: $font-color;
        text-align: center;

        .btn-back {
            font-size: 1.8em;
            line-height: 1em;
            float: left;
            color: $button-color;
            padding-left: 10px;
            transform: scaleX(0.6);
        }

        h1 {
            font-size: 0.6em;
            font-weight: 500;
            width: 70%;
            margin-top: 0.6em;
            margin-bottom: 0.1em;
            margin-left: auto;
            margin-right: auto;
        }

        p {
            color: $accent-color;
            font-size: 0.5em;
            width: 70%;
            margin-top: 0.1em;
            margin-left: auto;
            margin-right: auto;
        }
    }

    .player-name {
        display: inline-block;
        font-size: 0.5em;
        transform: rotate(-50deg);
        transform-origin: bottom left;
        position: relative;
        left: 20px;
        bottom: -12px;
    }

    .player-image {
        display: inline-block;
        width: 50px;
        height: 50px;
        border-radius: 25px;
        position: relative;
        left: 0;
        bottom: -12px;
    }

    table {
        margin: auto;
        border-spacing: 4px;
        thead,
        tbody,
        tfoot {
            display: block;
            th {
                box-sizing: border-box;
                min-width: 55px;
                max-width: 55px;
                &:first-of-type {
                    min-width: 90px;
                    width: 90px;
                }
            }
        }
        thead {
            min-height: 54px;
        }
        tbody {
            height: 494px;
            overflow-y: auto;
            overflow-x: hidden;
        }
        tfoot {
            color: #ddd;
        }
    }

    .buttons-container {
        position: fixed;
        bottom: 0;
        padding-bottom: 20px;
        width: 100vw;
        .buttons {
            text-align: left;
            padding: 0.5em 0;
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;

            button {
                font-family: 'Quicksand';
                font-size: 0.7em;
                background: $button-color;
                color: #fff;
                border-radius: 10px;
                border: 0;
                padding: 0.7em;
            }
        }
    }
}
