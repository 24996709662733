@import '../../../variables.scss';

.rounds {
    color: $font-color;
    margin-bottom: 15px;

    .filters {
        div {
            margin-right: 5px;
            display: inline-block;
            label {
                display: block;
            }
        }
    }
}
