.map {
    z-index: 99;
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.8);
    padding: 1em;
    box-sizing: border-box;

    img {
        width: 100%;
        height: auto;
    }

    .close-btn {
        color: #fff;
        position: absolute;
        top: 1em;
        right: 1em;
        width: 1em;
        height: 1em;
        line-height: 1em;
        border: 3px solid #fff;
        border-radius: 1em;
        padding: 0.1em;
    }
}

.hole {
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 3px;
    min-width: 90px;
    width: 90px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;

    .number {
        display: inline-block;
        margin: 0 10px;
        font-weight: 300;
        padding: 0.2em 0;
        width: 50px;
    }
    .hole-info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-top: 3px;
        padding-right: 5px;
        width: 40px;
        .par {
            background: #009378;
            font-size: 0.5em;
            font-weight: 700;
            padding: 0 0.3em;
            border-radius: 30px;
            color: #fff;
            min-width: 25px;
        }
        .distance {
            font-size: 0.5em;
            margin-top: 3px;
        }
    }
}
